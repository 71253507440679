<template>
  <div class="page half-width">
    <div class="page-content" @scroll="onScroll" ref="page">
      <div class="page-header" id="page-history-header">
        <div class="page-title">История</div>
      </div>
      <!--      <Searchbar
              v-model="filterParams.query"
              name="search"
              placeholder="Поиск по вакансии, заказчику"
              @input="loadList"
            />-->
      <!--      <Loader height="200" v-if="loading"/>-->
      <div class="history-list" v-if="historyByDate.length">
        <div class="history-list_section" v-for="(arr, key) in historyByDate" :key="key">
          <div class="history-list_section_head">
            <span v-if="new Date(key).getFullYear() === new Date().getFullYear()">
              {{
                $moment(key)
                  .locale('ru')
                  .format('DD MMMM, ddd')
              }}
            </span>
            <span v-else>
              {{
                $moment(key)
                  .locale('ru')
                  .format('YYYY DD MMMM, ddd')
              }}
            </span>
          </div>
          <ul>
            <li v-for="(item) in arr" :key="item.id">
              <div class="history-list_item" @click="itemClick(item)">
                <div class="history-list_item_media">
                  <div v-if="item.type ==='custom'" class="custom-leading">
                    <img class="custom-leading-image" v-if="item.image" :src="item.image" alt="">
                    <div class="custom-leading-letters" v-else-if="item.imageLetters">{{ item.imageLetters }}</div>
                  </div>
                  <CandidateMedia
                    :candidate="getCandidateFromItem(item)"
                    v-else-if="getCandidateFromItem(item)"
                  />
                </div>
                <div class="history-list_item_inner" v-if="item.type !== 'custom'">
                  <div class="history-list_item_title">
                    {{ getItemTitle(item) }}
                    <EventRating v-if="Number(item?.event?.rate)" :rating="item.event.rate"/>
                  </div>
                  <div class="history-list_item_subtitle" v-if="getCandidateFromItem(item)">
                    {{ getCandidateFromItem(item).name }} {{ getCandidateFromItem(item).surname }}
                  </div>
                  <div class="history-list_item_subtitle">
                    {{
                      $moment(Number(`${item.datetime}000`))
                        .locale('ru')
                        .format('HH:mm')
                    }}
                  </div>
                  <div class="history-list_item_comment" v-if="item?.event?.comment">
                    <div class="icon icon-comment"/>
                    <span v-html="item.event.comment"></span>
                  </div>
                </div>
                <div class="history-list_item_inner" v-else>
                  <div class="history-list_item_title">
                    {{ item.title }}
                    <EventRating v-if="item.rate" :rating="item.rate"/>
                  </div>
                  <div class="history-list_item_subtitle" v-if="item.subtitle">
                    {{ item.subtitle }}
                  </div>
                  <div class="history-list_item_subtitle">
                    {{$moment(Number(`${item.datetime}000`)).locale('ru').format('HH:mm') }}
                  </div>
                  <div class="history-list_item_comment" v-if="item.comment">
                    <div class="icon icon-comment"/>
                    <span v-html="item.comment"></span>
                  </div>
                  <div class="history-list_item_comment" v-else-if="item.text">
                    <div class="icon icon-comment"/>
                    <span>{{item.text}}</span>
                  </div>
                </div>
                <div class="history-list_item_after">
                  <div class="icon icon-arrow flip-horizontally"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="history-list">
        <no-results>
          <template #title>История пустая</template>
          <template #text>Вы пока не совершили ни одного действия</template>
        </no-results>
      </div>
      <v-btn @click="scrollToTop"
             depressed rounded :ripple="false" :class="`back-to-top-button`" width="50" height="50">
        <v-icon color="primary">{{ `mdi-arrow-${scrollTop > 200 ? 'up' : 'down'}-circle` }}</v-icon>
      </v-btn>
    </div>
    <div class="page-right-content">
      <!--      <InviteBlock/>-->
    </div>
  </div>
</template>

<script>
import InviteBlock from '@/views/profile/InviteBlock.vue';
import EventRating from '@/views/event/EventRating.vue';
import CandidateMedia from '@/views/candidate/CandidateMedia';
import NoResults from '@/components/NoResults';

export default {
  name: 'History',
  components: {
    NoResults,
    CandidateMedia,
    EventRating,
    InviteBlock,
  },
  data() {
    return {
      filterParams: {
        query: '',
        page: 0,
        limit: 50,
      },
      history: [],
      limitedHistory: [],
      loading: false,
      rowHeight: 96,
      itemsLengthCurrentPage: 50,
      scrollTop: 0,
    };
  },
  methods: {
    scrollToTop() {
      this.$refs.page.scrollTo({
        top: this.scrollTop > 200 ? 0 : 1000000,
        behavior: 'smooth'
      });
    },
    onScroll(e) {
      // this.timeout && clearTimeout(this.timeout);
      // this.timeout = setTimeout(() => {
      if (this.loading) return;
      const { scrollTop } = e.target;
      this.scrollTop = scrollTop;
      const rows = Math.ceil(scrollTop / this.rowHeight);
      if (this.limitedHistory.length - rows < 2 / 3 * this.filterParams.limit) {
        if (this.itemsLengthCurrentPage > 0) {
          this.filterParams.page++;
          this.loadList();
        }
      }
      // this.$nextTick(() => {
      //   e.target.scrollTop = scrollTop;
      // });
      // }, 20);
    },
    itemClick(item) {
      const { id } = this.getCandidateFromItem(item);
      this.$router.push(`/candidates/candidate/${id}`);
    },
    getItemTitle(item) {
      const { type } = item;
      if (type === 'candidate_added') return 'Кандидат добавлен';
      if (type === 'candidate_favorite') return 'Добавлен в избранное';
      if (type === 'event_rate') return 'Оценка';
      if (type === 'event_comment') return 'Комментарий';
      return '';
    },
    getCandidateFromItem(item) {
      const { type } = item;
      const template = { image: {} };
      if (type === 'candidate_added' || type === 'candidate_favorite') return { ...template, ...item.candidate };
      if (type === 'event_rate' || type === 'event_comment') return { ...template, ...item?.event?.candidate || {} };
      return null;
    },
    loadList() {
      this.loading = true;
      this.$server.request2('event/getHistory', this.filterParams, (data) => {
        this.loading = false;
        if (this.filterParams.page > 0) {
          this.history = this.history.concat(data.response);
          this.limitedHistory = this.limitedHistory.concat(data.response);
        } else {
          this.history = data.response;
          this.limitedHistory = data.response;
        }
        this.itemsLengthCurrentPage = data.response.length;

      }, () => {
        this.loading = false;
      });
    },
  },
  computed: {
    historyByDate() {
      const map = new Map();
      this.history.forEach((item) => {
        const date = this.$moment(Number(`${item.datetime}000`))
          .format('YYYY-MM-DD');

        if (map.get(date)) {
          map.get(date)
            .push(item);
          return;
        }
        map.set(date, [item]);
      });
      return Object.fromEntries(map.entries());
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>

<style scoped lang="scss">

.history-list {
  width: 100%;
  max-width: 580px;
  position: relative;

  &_section {
    margin-bottom: 40px;

    &_head {
      font-size: 14px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .4);
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-top: 1px solid var(--border-color);

      &:last-child {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }

  &_item {
    cursor: pointer;
    display: flex;

    .custom-leading {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: var(--color-gray);
      font-size: 12px;
      &-image {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
      &-letters {
        text-transform: uppercase;
      }
    }

    &_media {
      flex-shrink: 0;
      min-width: 30px;
      padding: 30px 0;
      margin-right: 16px;

      .candidate-media {
        width: 30px;
        height: 30px;
      }
    }

    &_inner {
      padding: 24px 0;
      min-width: 0;
      width: 100%;

      & > * {
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;

      .event-rating {
        margin-left: 6px;
      }
    }

    &_subtitle {
      font-size: 14px;
      line-height: 16px;
    }

    &_comment {
      margin-top: 10px;
      font-size: 13px;
      line-height: 17px;
      color: rgba(var(--page-color-main-rgb), .5);
      display: flex;

      .icon {
        font-size: 16px;
        margin-right: 6px;
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }

    &_after {
      flex-shrink: 0;
      padding: 36px 0;

      .icon {
        font-size: 16px;
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }
  }
}
</style>
